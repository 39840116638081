<template>
  <el-dialog
    title="设置状态&备注"
    :visible.sync="visible"
    width="600px"
    :before-close="closedone"
    :close-on-click-modal="false"
  >
    <el-form label-width="120px" label-position="left">
      <el-form-item label="流程重置">
        <el-checkbox v-model="step" :disabled="!checkable">设为“待审核”的同时，系统将尝试取消已创建的出库单。</el-checkbox>
      </el-form-item>
      <el-form-item label="状态异常">
        <el-checkbox v-model="status">将订单状态标注为“异常”</el-checkbox>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" :rows="5" v-model="remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="saveremark()">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  editWayfairSaleRemark,
  editWayfairSaleDetailRemark
} from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      step: false,
      type: '',
      status: false,
      remark: '',
      po: '',
      checkable: true
    }
  },
  methods: {
    show (po, type, step) {
      this.po = po
      this.type = type
      this.visible = true
      this.checkable = !(step === '1' || step === '9')
    },
    closedone (done) {
      done()
    },
    saveremark () {
      if (this.type === 1) {
        editWayfairSaleRemark({
          po: this.po,
          step: this.step,
          status: this.status,
          remark: this.remark
        })
          .then(data => {
            this.$emit('edit-success')
            this.hide()
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        editWayfairSaleDetailRemark({
          po: this.po,
          step: this.step,
          status: this.status,
          remark: this.remark
        })
          .then(data => {
            this.$emit('edit-success')
            this.hide()
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    hide () {
      this.visible = false
      this.step = false
      this.status = false
      this.po = ''
      this.remark = ''
    }
  }
}
</script>

<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>Wayfair</el-breadcrumb-item>
        <el-breadcrumb-item>销售订单</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <el-col :span="5">
        <h2>Wayfair Sales Order</h2>
      </el-col>
      <el-col :span="2" :offset="14">
        <el-button
          type="text"
          size="small"
          v-if="detaildata.step < 9 && detaildata.staus != 0"
          @click="$refs['edit-sale-remark-dialog'].show(detaildata.po, 2)"
          >设置状态&备注</el-button
        >
      </el-col>
      <el-col :span="2" :offset="1">
        <el-button
          type="text"
          size="small"
          v-if="detaildata.step < 9 && detaildata.staus != 0"
          @click="$refs['edit-sale-address-dialog'].show(detaildata)"
          >编辑</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-form :inline="true">
        <el-row>
          <el-col :span="5">
            <el-form-item label="PO">
              &nbsp;&nbsp;{{ detaildata.po }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="流程">
              &nbsp;&nbsp;{{ detaildata._step }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="状态">
              &nbsp;&nbsp;{{ detaildata._status }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="创建时间">
              &nbsp;&nbsp;{{ detaildata.created_at }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5">
            <el-form-item label="姓名">
              &nbsp;&nbsp;{{ detaildata.ship_to.name }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="电话">
              &nbsp;&nbsp;{{ detaildata.ship_to.phone }}
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="省市区">
              country:{{ detaildata.ship_to.country }}
              <span v-if="detaildata.ship_to.state"
                >state:{{ detaildata.ship_to.state }}</span
              >
              <span v-if="detaildata.ship_to.city"
                >city:{{ detaildata.ship_to.city }}</span
              >
            </el-form-item>
          </el-col>
          <el-col :span="5" :offset="1">
            <el-form-item label="地址">
              地址1：{{ detaildata.ship_to.addr1 }}
              <span v-if="detaildata.ship_to.addr2"
                >地址2;{{ detaildata.ship_to.addr2 }}</span
              >
              <span v-if="detaildata.ship_to.zip"
                >邮编:{{ detaildata.ship_to.zip }}</span
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="备注">
              &nbsp;&nbsp;{{ detaildata.remark }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-bottom:10px;">
          <el-col :span="1">
            <el-dropdown v-if="detaildata.step == 1">
              <el-button type="primary" size="mini"
                >审核<i class="el-icon-arrow-down el-icon--right"></i
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="confirmOrder(2)"
                  >审核通过</el-dropdown-item
                >
                <el-dropdown-item @click.native="confirmOrder(-1)"
                  >审核不通过</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col :span="2" :offset="1" v-if="detaildata.step == 2">
            <el-button type="primary" size="mini" @click="confirmOrder(3)"
              >发货出库</el-button
            >
          </el-col>
          <el-col :span="2" :offset="1" v-if="detaildata.step == 3">
            <el-button type="primary" size="mini" @click="reshipOrder()"
              >重新发货</el-button
            >
          </el-col>
          <el-col
            :span="2"
            :offset="1"
            v-if="detaildata.step >= 2 && detaildata.step < 9"
          >
            <el-button type="danger" size="mini" @click="cancelOrDer('')"
              >整单作废</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-row>
    <el-row>
      <el-table width="100%" border stripe :data="detaildata.item">
        <el-table-column prop="id" label="# Item"> </el-table-column>
        <el-table-column prop="mfn" label="MFN"> </el-table-column>
        <el-table-column prop="sku" label="Sku"> </el-table-column>
        <el-table-column prop="qty" label="qty"> </el-table-column>
        <el-table-column prop="price" label="price"> </el-table-column>
        <el-table-column prop="_status" label="状态"> </el-table-column>
        <!-- <el-table-column prop="_warehouse" label="出库仓库">
                </el-table-column>
                <el-table-column prop="outbound_no" label="出库单号">
                </el-table-column> -->
        <el-table-column>
          <template slot-scope="scope">
            <el-button type="text" @click="$refs['rt-inventory-query'].show(detaildata.ec_id, scope.row)"
              >查看库存</el-button
            >
            <!-- <el-button type="text" @click="$refs['cancel-outorder-dialog'].show(detaildata.po,scope.row._id)">取消</el-button> -->
            <el-button type="text" @click="cancelOrDer(scope.row.sku)"
              >取消</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row>
      <h4>发货记录</h4>
      <el-table width="100%" border stripe :data="detaildata.io_detail">
        <el-table-column prop="warehouse" label="仓库"> </el-table-column>
        <el-table-column prop="no" label="NO"> </el-table-column>
        <el-table-column prop="sku" label="Sku"> </el-table-column>
        <el-table-column prop="qty" label="qty"> </el-table-column>
        <el-table-column prop="io_no" label="出库单号"> </el-table-column>
      </el-table>
    </el-row>
    <edit-sale-dialog
      ref="edit-sale-dialog"
      @edit-success="getSalesDetai()"
    ></edit-sale-dialog>
    <edit-sale-remark-dialog
      ref="edit-sale-remark-dialog"
      @edit-success="getSalesDetai()"
    ></edit-sale-remark-dialog>
    <edit-sale-address-dialog
      ref="edit-sale-address-dialog"
      @edit-addr-success="getSalesDetai()"
    ></edit-sale-address-dialog>
    <cancel-outorder-dialog
      ref="cancel-outorder-dialog"
      @cancel-success="getSalesDetai()"
    ></cancel-outorder-dialog>

    <!-- 实时库存查询 -->
    <RtInventoryQuery
      title="实时库存查询"
      ref="rt-inventory-query"
    />
  </div>
</template>
<script>
import {
  getWayfairSaleDetail,
  confirmDetail,
  // cancelOutOrder,
  reship
} from '@/api/amazon'
import editSaleDialog from './edit-sale-dialog'
import editSaleRemarkDialog from './edit-sale-remark-dialog'
import cancelOutorderDialog from './cancel-outorder-dialog'
import editSaleAddressDialog from './edit-sale-address-dialog'
import RtInventoryQuery from './RtInventoryQuery'
import { API } from '@/service/api'

const msgboxContent = {
  render (h) {
    const textarea = h('el-input', {
      props: {
        type: 'textarea',
        value: this.remark
      },
      attrs: {
        placeholder: '备注'
      },
      on: {
        input: e => {
          this.remark = e
        }
      }
    })
    const checkbox = h('el-checkbox', {
      props: { value: this.isForce },
      on: { change: e => (this.isForce = e) }
    }, '')

    return h('div', null, [
      h('p', null, this.message),
      h('div', null, [
        checkbox,
        h('span', null, '强制删除海外仓出库记录（即使海外仓出库单取消失败，系统也会删除出库记录），后续海外仓相关业务请及时在系统外操作。')
      ]),
      textarea
    ])
  },
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      remark: '',
      isForce: false
    }
  },
  methods: {
    reset () {
      this.remark = ''
      this.isForce = false
    },

    getData () {
      return {
        remark: this.remark,
        isForce: this.isForce
      }
    }
  }
}

export default {
  components: {
    editSaleDialog,
    editSaleRemarkDialog,
    cancelOutorderDialog,
    editSaleAddressDialog,
    // eslint-disable-next-line vue/no-unused-components
    msgboxContent,
    RtInventoryQuery
  },
  data () {
    return {
      detaildata: {
        ship_to: {}
      },
      po: this.$route.params.po,
      reload: false
    }
  },
  created () {
    this.getSalesDetai()
  },
  methods: {
    getSalesDetai () {
      getWayfairSaleDetail({ po: this.$route.params.po })
        .then(data => {
          this.detaildata = data
        })
        .catch(err => {
          console.log(err)
        })
    },
    confirmOrder (step) {
      let title = ''
      let messg = ''
      if (step === 2) {
        title = '审核通过'
        messg = '销售单审核确认后，系统将自动为其创建出库单。确定审核通过'
      } else if (step === -1) {
        title = '审核不通过'
        messg = '确定取消该订单吗？'
      } else if (step === 3) {
        title = '发货出库'
        messg = '系统将安排订单发货：下载PDF并创建海外仓出库单。确定执行审核？'
      }
      this.$confirm('提示', { title: title, message: messg })
        .then(_ => {
          confirmDetail({
            step: step,
            po: this.$route.params.po
          })
            .then(data => {
              this.getSalesDetai()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    reshipOrder () {
      this.$confirm('提示', {
        title: '重新发货',
        message:
          '系统将尝试取消已创建的海外仓出库单，并重新发货创建海外仓出库单。确定重新发货？'
      })
        .then(_ => {
          reship({
            po: this.$route.params.po,
            warehouse: 1
          })
            .then(data => {
              this.getSalesDetai()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    renderConfirmBox ({ message }) {
      const h = this.$createElement

      // const textarea = h('el-input', {
      //   key: this.msgboxData.key++,
      //   props: {
      //     type: 'textarea',
      //     placeholder: '备注',
      //     value: this.msgboxData.remark
      //   },
      //   on: {
      //     input: e => {
      //       console.log(e, this.msgboxData.key)
      //       this.msgboxData.remark = e
      //       this.$forceUpdate()
      //     }
      //   }
      // })
      const html = h('msgboxContent', { props: { message } })
      console.log(html)
      return new Promise((resolve, reject) => {
        this.$msgbox({
          title: '整单作废',
          message: html,
          confirmButtonText: '保存',
          beforeClose: (action, _, done) => {
            if (action === 'confirm') {
              const data = html.componentInstance.getData()
              html.componentInstance.reset()
              resolve({ data, done })
            } else {
              done()
              html.componentInstance.reset()
              reject(new Error('取消'))
            }
          }
        })
      })
    },
    cancelOrDer (sku) {
      const message =
        sku === ''
          ? '作废订单的同时，系统还将尝试取消已创建的海外仓出库单。确定作废该订单？'
          : '取消明细的同时，系统将尝试取消未出库的出库单（同个出库单只会被操作一次），并邮件通知最终取消结果。确定操作？'
      this.renderConfirmBox({ message })
        .then(({ data, done }) => {
          console.log(data)
          // TODO: 接口在哪？ 需要知道普通的取消是否和这个弹窗完全一样
          const params = {
            po: this.po,
            sku,
            remark: data.remark,
            force: data.isForce
          }
          API.Sales.cancelOrder(params)
            .then(() => {
              done()
            })
        })
      // this.$confirm('提示', { title: '取消订单', message: messg })
      //   .then(_ => {
      //     cancelOutOrder({
      //       po: this.detaildata.po,
      //       sku: sku
      //     })
      //       .then(data => {
      //         this.getSalesDetai()
      //       })
      //       .catch(err => {
      //         console.log(err)
      //       })
      //   })
      //   .catch(_ => {})
    }
  }
}
</script>

<script>
import { defineComponent, ref, unref } from '@vue/composition-api'
import FastDialog from '@/components/fast-dialog'
import GodTable from '@/components/god-table'
import { API } from '@/service/api'

export default defineComponent({
  components: {
    FastDialog,
    GodTable
  },
  setup () {
    const dialog = ref(null)
    const params = ref({
      ec_id: null,
      mfn: null,
      sku: null
    })

    return {
      dialog,
      show (ecId, item) {
        const p = {
          ec_id: ecId,
          mfn: item.mfn,
          sku: item.sku
        }
        params.value = p
        dialog.value && dialog.value.show()
      },
      dataProvider (pageData) {
        return API.Sales.getWayfairRealTimeInventory({ ...unref(params), ...pageData })
      }
    }
  }
})
</script>

<template>
  <FastDialog
    ref="dialog"
    @opened="$refs.table.refresh()"
  >
    <div>
      <GodTable
        ref="table"
        paging
        :data-provider="dataProvider"
      >
        <el-table-column label="仓库" prop="_warehouse"></el-table-column>
        <el-table-column label="仓库SKU" prop="_sku"></el-table-column>
        <el-table-column label="自有库存" prop="qty"></el-table-column>
        <el-table-column label="共享库存" prop="qty_share"></el-table-column>
        <el-table-column label="冻结库存" prop="qty_locked"></el-table-column>
        <el-table-column label="可用库存">
          <template slot-scope="scope">
            {{scope.row.qty+scope.row.qty_share+scope.row.qty_locked}}
          </template>
        </el-table-column>
      </GodTable>
    </div>
  </FastDialog>
</template>

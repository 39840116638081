<template>
  <el-dialog
    title="取消出库单"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="500px"
  >
    <el-form>
      <el-form-item>
        <el-tag type="info" size="medium"
          >系统将尝试取消未出库的出库单，并且不会再次发货。确定取消出库单？</el-tag
        >
      </el-form-item>
      <el-form-item>
        <el-tag type="warning" size="medium"
          >注意：相同仓库的出库单只要取消一次即可，重复操作将被系统忽略。</el-tag
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formdata.is_cancelOrder"
          >设置整个销售单为 “已取消” （默认选中状态）</el-checkbox
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formdata.is_syncThree"
          >同时取消第三方仓库出库单 （默认选中）</el-checkbox
        >
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="handleConfirm()">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { cancelOutOrder } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        is_cancelOrder: true,
        is_syncThree: true
      }
    }
  },
  methods: {
    show (po, _id) {
      this.visible = true
      this.formdata.po = po
      this.formdata._id = _id
      console.log(this.formdata._id)
    },
    closedone (done) {
      done()
    },
    handleConfirm () {
      cancelOutOrder(this.formdata)
        .then(data => {
          this.$emit('cancel-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    },
    hide () {
      this.formdata = {}
      this.visible = false
    }
  }
}
</script>

<template>
  <el-dialog
    title="设置状态"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="500px"
  >
    <el-form :inline="true" size="small" label-width="120px">
      <el-form-item label="流程">
        <el-select v-model="formdata.step">
          <el-option
            v-for="item in sysConfig.WayfairSaleOrder.step"
            :key="item.key"
            :value="item.key"
            :label="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="formdata.status">
          <el-option
            v-for="item in sysConfig.WayfairSaleOrder.status"
            :key="item.key"
            :value="item.key"
            :label="item.val"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="save()">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { editWayfairSale } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {}
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (po) {
      this.visible = true
      this.formdata.po = po
    },
    closedone (done) {
      done()
    },
    hide () {
      this.formdata = {}
      this.visible = false
    },
    save () {
      const jsondata = {
        po: this.formdata.po,
        step: this.formdata.step,
        status: this.formdata.status
      }
      editWayfairSale(jsondata)
        .then(data => {
          this.$emit('edit-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

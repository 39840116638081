<template>
  <el-dialog
    title="编辑地址"
    :visible.sync="visible"
    :before-close="closedone"
    :close-on-click-modal="false"
    width="500px"
  >
    <el-form size="small" label-width="120px" label-position="left">
      <el-form-item label="姓名:">
        <el-input v-model="formdata.ship_to.name"></el-input>
      </el-form-item>
      <el-form-item label="电话:">
        <el-input v-model="formdata.ship_to.phone"></el-input>
      </el-form-item>
      <el-form-item label="国州市:">
        <el-input
          v-model="formdata.ship_to.country"
          placeholder="国"
          style="width:100px;"
        ></el-input>
        <el-input
          v-model="formdata.ship_to.state"
          placeholder="州"
          style="width:100px;"
        ></el-input>
        <el-input
          v-model="formdata.ship_to.city"
          placeholder="市"
          style="width:100px;"
        ></el-input>
      </el-form-item>
      <el-form-item label="地址1:">
        <el-input v-model="formdata.ship_to.addr1"></el-input>
      </el-form-item>
      <el-form-item label="邮编:">
        <el-input v-model="formdata.ship_to.zip"></el-input>
      </el-form-item>
      <el-form-item label="地址2:">
        <el-input v-model="formdata.ship_to.addr2"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="save()">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { editAddr } from '@/api/amazon'
export default {
  data () {
    return {
      visible: false,
      formdata: {
        ship_to: {}
      }
    }
  },
  computed: {
    ...mapGetters(['sysConfig'])
  },
  methods: {
    show (detaildata) {
      this.visible = true
      this.formdata.po = detaildata.po
      this.formdata.ship_to = detaildata.ship_to
    },
    closedone (done) {
      this.formdata = { ship_to: {} }
      done()
    },
    hide () {
      this.formdata = {}
      this.visible = false
    },
    save () {
      const jsondata = {
        po: this.formdata.po,
        name: this.formdata.ship_to.name,
        phone: this.formdata.ship_to.phone,
        addr1: this.formdata.ship_to.addr1,
        addr2: this.formdata.ship_to.addr2,
        zip: this.formdata.ship_to.zip,
        country: this.formdata.ship_to.country,
        state: this.formdata.ship_to.state,
        city: this.formdata.ship_to.city
      }
      editAddr(jsondata)
        .then(data => {
          this.$emit('edit-addr-success')
          this.hide()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
